@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: white;
  scrollbar-width: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
}

/* link underline animation */
.link a {
  display: block;
  position: relative;
  padding: 0.2em 0;
}

.link a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: white;
  mix-blend-mode: difference;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.link a:hover::after,
.link a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.1em, 0);
}

/* Slick carousel */
.slick-slider {
  max-width: 30vw;
  width: 100%;
}

.free-palestine {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Palestine.svg/1200px-Flag_of_Palestine.svg.png");
  background-size: cover;
  background-clip: text;
  background-repeat: round;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
