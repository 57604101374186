.text-image-profile {
  background: -webkit-linear-gradient(
    -45deg,
    rgb(128, 0, 255),
    rgb(0, 247, 255)
  );
  background-size: 300%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animatedName 4.5s ease-in-out infinite;
}

@keyframes animatedName {
  0% {
    background-position: 0px 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0px 50%;
  }
}
