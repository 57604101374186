/* cursor */
.cursor {
  width: 10px;
  height: 10px;
  border: 1px solid black;
  background-color: white;
  border-radius: 50%;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  mix-blend-mode: difference;
  z-index: 100;
  pointer-events: none;
}

.cursor::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border: 1px solid white;
  border-radius: 50%;
  top: -6px;
  left: -6px;
  animation: cursorBorderAnimation 1s infinite alternate;
  mix-blend-mode: difference;
}

.expand {
  animation: cursorAnimationOnClick 0.3s forwards;
  border: 1px solid red;
}

@keyframes cursorAnimationOnClick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}

.cursor.active {
  width: 5rem;
  height: 5rem;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  backdrop-filter: blur(9px);
  position: fixed;
  transition-duration: 200ms;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  mix-blend-mode: normal;
  transform: translate(-45%, -45%);
}

.cursor.active::after {
  opacity: 0;
}

@keyframes cursorOnLink {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes cursorBorderAnimation {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(0.6);
  }
}
