.projects-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 100%;
  @apply p-2 py-10 md:p-8 lg:px-[clamp(2rem,3vw,5rem)] md:py-20 my-10 bg-gray text-white rounded-xl space-y-14;
}

.projects-cards-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  @apply lg:flex-row lg:space-x-5 lg:space-y-0 space-y-5;
}
